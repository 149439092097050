import React, { useEffect, useState } from "react";
import { Modal, Button, DatePicker } from "antd";
import Wrapper from "./style";
import { CSVLink } from "react-csv";
import { NotificationManager } from "react-notifications";
import { customBaseUrl, httpGet, httpPost } from "../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../utils/loader";

function GenCard ({ showModal, hideModal, handleGenerate,// setCount, count, dataList 
}){
  const [count, setCount] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [cardTypes, setCardTypes] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedType, setSelectedType] = useState('');


///api/v1/qrcode/generateQRCode/count/1
const profileName = localStorage.getItem('fullName');

const genCards = async () => {
  showLoader();
  const res = await httpPost(`${customBaseUrl.cardUrl}/dummy-account/download-bulk-dummy-card-files?numberOfCards=${count}`,{})
  hideLoader();
  Swal.fire('Done!');

}

const handleGenerate1 = async() => {

  showLoader();
  if(!count ) return Swal.fire('error','Invalid input');
  
  let d = new Date();
    // const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    // {
    //   payload: JSON.stringify(postData),
    //   "requestId": `REQ${d.getTime()}`,
    //   "requestType": "generateQRCode",
    //   customerName: profileName
    // } )
    const postData = {
      cardProduct: selectedProduct,
      cardType: selectedType,
      numberOfCards: Number(count)
    }
    const res = await httpPost(
      `${customBaseUrl.cardUrl}/api/cards/generate`,
      postData
    );

    if (res?.status || res.length > 0) {
      Swal.fire(res?.message ?? 'Cards generated successfully');
      hideModal(false);
      hideLoader();
    } else {
      Swal.fire(res?.message);
      hideLoader();
    }

 /* if(!count) return Swal.fire('Oops', 'Count field is empty')
  let response = await httpGet(`${customBaseUrl.qrCodeUrl}/api/v1/qrcode/generateQRCode/count/${count}`)
 showLoader();
  if (response.status){    
    hideLoader();
    Swal.fire('Success', response?.message);
    //setQRStatus( response?.message);
    hideModal(false);
  }else{
    hideLoader();
    console.log(response?.message)
  }*/
}

const getCardTypes = async (e,page, size) => {
  e?.preventDefault();
  showLoader();
 
  let request = await httpGet(
    `${customBaseUrl.cardUrl}/card/list/card-type`
  );
  if (request?.status) {
    console.log(request)
    hideLoader();
    setCardTypes(request?.data)
  }else{
    console.log(request)
    hideLoader();

  }
  
 
};

const getProducts = async (e,page, size) => {
  e?.preventDefault();
  showLoader();

  
  let request = await httpGet(
    `${customBaseUrl.cardUrl}/card/list/card-product`
  );
  if (request?.status) {
    console.log(request)
    hideLoader();
    setProducts(request?.data)
  }else{
    console.log(request)
    hideLoader();

  }
  
 
};

useEffect(()=>{
  getCardTypes()
  getProducts()
},[]);
  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title=""
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: "27px",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>
      </div>
      <Wrapper>
        <div className="title">Generate Bulk Cards</div>
        <div className="cw-content">
        <div className="input-content border-dark">
          <div className="label">Enter number of cards to be generated</div>
          <input onChange={(e) => setCount(e.target.value)} value={count} name="" id="" className="border border-dark"/>
        </div>
        <div className="input-content border-dark">
          <div className="label">Select cards products</div>
          <select
          value={selectedProduct}
            className="border"
            onChange={(e) => setSelectedProduct(e.target.value)}
          >
            <option>Select product</option>
            {products?.map((e) => 
              <option value={e?.productName} key={e?.productName}>
                {e?.productName}
              </option>
            )}
          </select>
       </div>
        <div className="input-content border-dark">
          <div className="label">Select cards type</div>
          <select
            className="border"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <option>Select product</option>
            {cardTypes?.map((e) => 
              <option value={e?.type} key={e?.type}>
                {e?.type}
              </option>
            )}
          </select>
        </div>         
        </div>
      </Wrapper>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 50
        }}
      >
        
        <button style={{
            marginLeft: "auto",
            marginRight: "auto",
            background: "#FF4B01",
            color: "#fff",
            borderRadius: "3px",
            fontSize: "14px",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            padding: "10px 25px",
          }}
          onClick = {handleGenerate1}
          >
          Generate Cards
          </button>
      </div>
    </Modal>
  );
};

export default GenCard;
